import React from "react";
import { List } from "./List";

const MakeMoneyOnKongaLists = () => {
    return (
        <ul className='list-group bg-transparent m-0 p-0'>
            <List  text ='吉爾公司' />
        </ul>
    )
}

export default MakeMoneyOnKongaLists ;

const LatestProducts = [     
    {
        name : '“康为医疗”PVC兔热源工作台' ,
        // newPrice : '₦49,999',
        // oldPrice : '₦53,790' ,
        // discount : '₦3,791' ,
        productImage : require('../Assets/img/40_1494840360.jpg').default     
    } ,
 
    {
        name : '蛋雞籠 KMKN-3-6-55 適用於 ' ,
        name1:"45-55 頭（3 層，6 部分）",
        // newPrice : '₦267,800',
        // oldPrice : '₦290,000' ,
        // discount : '₦22,200' ,
        productImage : require('../Assets/img/carus2.jpg').default        
    } ,
    {
        name : '巴納亞·斯坦齊亞·德利亞·' ,
        name1:"普里戈托夫萊尼亞·科克泰利·S·林澤羅姆",
        // newPrice : '₦247,800',
        // oldPrice : '₦250,000' ,
        // discount : '₦2,200' ,
        productImage : require('../Assets/img/carus3.jpg').default        
    } ,
    {
        name : '用於腹腔鏡訓練器的模擬器盒、、' ,
        name1:"帶高清攝影機的腹腔鏡手術練習套件",
        name2:"9 個訓練模組、4 個練習設備。 可連接PC/手機/平板",
        // newPrice : '€868.47',
        // oldPrice : '€968.47' ,
        // discount : '€100' ,
        productImage : require('../Assets/img/carus4.jpg').default        
    } ,
    {
        name : '魚骨骼嵌入標本透明樹脂真魚' ,
        name1:"骨動物骨骼標本模型生物解剖教具",
        // newPrice : '₦49,999',
        // oldPrice : '₦53,790' ,
        // discount : '₦3,791' ,
        productImage : require('../Assets/img/carus5.jpg').default        
    } ,
    {
        name : 'A6101 大腦右半球模型，V 剖面' ,
        // newPrice : '9048,00 руб.',
        // oldPrice : '7691,00 руб.' ,
        // discount : '256,56 руб' ,
        productImage : require('../Assets/img/skiler2.jpg').default        
    } ,
    
]

/*product description is includued in the product image. 
there no need to write it. so only image source will be exported */

const bestProduct = [
     require('../Assets/img/skilet01.jpg').default,
     require('../Assets/img/skiler2.jpg').default,
     require('../Assets/img/carus2.jpg').default,
     require('../Assets/img/carus3.jpg').default,
     require('../Assets/img/carus4.jpg').default,     
     require('../Assets/img/carus5.jpg').default,
]

const specialProducts = [
    {         
        name : '天竺鼠籠架 TS-12'  ,
        description : '尺寸：1700x620x1720毫米',
        ProductImageSrc : require('../Assets/img/T-12.jpg').default,
        link:'Single.jsx'
    },
    {
        name : '實驗室兔籠架 RS-15' ,
        description : '尺寸：1900x620x1720毫米' ,
        ProductImageSrc :  require('../Assets/img/RS-15.jpg').default ,    
        link:'Single.jsx'     
    },
    {
        name : '“康为医疗”不锈钢冲洗式实验兔笼' ,
        description : ' 尺寸：2000×650×1720mm  ' ,
        ProductImageSrc :   require('../Assets/img/pro3.jpg').default,
        link:'Single.jsx'
    },
    {
        name :  '“康为医疗”不锈钢冲洗式实验兔笼 ',
        description :' 规格：2000×650×1720mm \n 笼子规格：500×350×320mm' ,
        ProductImageSrc :  require('../Assets/img/pro4.jpg').default ,
        link:'Single.jsx'
    },    
]

export { LatestProducts , bestProduct , specialProducts }

 
  
 
import React from 'react'
import img1 from "../Assets/img/T-12.jpg"
import img2 from "../Assets/img/RS-15.jpg"
import "../Styles/StyleSingle.css"
import { Navbar } from './Navbar'
import { useLocation } from 'react-router-dom';
import Footer from './Footer';


const dataSingle =[
  {
    img:`${img1}`,
    title:"天竺鼠籠架 TS-12",
    tex1:"尺寸：1700x620x1720毫米",
    text2:"裝置: . 包裝中包含籠子。",
    tex3:"可依要求安裝滾輪。",
    tex4:"電池數量：12",
    tex5:"籠具：豚鼠籠具TS-12",
    tex6:"材質：不鏽鋼",
    tex7:"單元放置：3 行，每行 4 個",
    tex8:"動物籠類型: 豚鼠籠",
    tex9:"保持架尺寸：500x400x260 毫米",
    tex10:"0.8mm厚304不銹鋼托盤板。",
    tex11:"0.3mm厚304不銹鋼送料器",
    
  },
  {
    img:`${img2}`,
    title:"實驗室兔籠架 RS-15",
    tex1:"尺寸：1900x620x1720毫米",
    text2:"內容： 籠子包含在交付套件中。 飲水器 500 毫升。",
    tex3:"可依要求安裝滾輪。",
    tex4:"電池數量：15",
    tex5:"籠子類型：RS15兔籠",
    tex6:"材質：不鏽鋼",
    tex7:"單元放置：3 行，每行 5 個",
    tex8:"動物籠類型: 兔籠",
    tex9:"保持架尺寸：500x320x300 毫米",
    tex10:"0.8mm厚304不銹鋼托盤板。",
    tex11:"0.3m厚304不銹鋼餵料器",
    
  }
]

function Single() {
  let location = useLocation()
  let newdata = location.hash.slice(1)
  return (
    <>
    <Navbar/>
    <div className='container w-75 pt-5 pb-5'>
      <div className='row'>
      <div>{dataSingle[newdata].title}</div>
        <div className='divimg'>
             <img className='w-100' src={dataSingle[newdata].img} alt="" />
        </div>
        <div>
        <div>
          <div className='text'>{dataSingle[newdata].tex1}</div>
          <div className='text'>{dataSingle[newdata].tex2}</div>
          <div className='text'>{dataSingle[newdata].tex3}</div>
          <div className='text'>{dataSingle[newdata].tex4}</div>
          <div className='text'>{dataSingle[newdata].tex5}</div>
          <div className='text'>{dataSingle[newdata].tex6}</div>
          <div className='text'>{dataSingle[newdata].tex7}</div>
          <div className='text'>{dataSingle[newdata].tex8}</div>
          <div className='text'>{dataSingle[newdata].tex9}</div>
          <div className='text'>{dataSingle[newdata].tex10}</div>
          <div className='text'>{dataSingle[newdata].tex11}</div>
        </div>
        </div>

      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Single
import React from "react";
import { List } from "./List";

const BuyingLists = () => {
    return (
        <ul className='list-group bg-transparent m-0 p-0'>
            <List  text ='買家安全中心' />
            <List  text ='常見問題解答' />
            <List  text ='送貨' />            
            <List  text ='退貨政策' /> 
            <List  text ='數位服務' /> 
            <List  text ='大量採購' /> 
        </ul>
    )
}

export default BuyingLists ;
import React from "react";
import { List } from "./List";

const AboutLists = () => {
    return (
        <ul className='list-group bg-transparent m-0 p-0'>
            <List  text ='聯絡我們' />
            <List  text ='關於我們' />
            <List  text ='職業機會' />
            <List  text ='我們的部落格' />
            <List  text ='論壇' />
            <List  text ='條款及條件' />
        </ul>
    )
}

export default AboutLists ;
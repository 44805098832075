import React from "react";
import { List } from "./List";

const PaymentLists = () => {
    return (
        <ul className='list-group bg-transparent m-0 p-0'>
            <List  text ='孔加支付' />
            <List  text ='瓦勒t' />
            <List  text ='氣魄' />            
            <List  text ='萬事達卡' /> 
            <List  text ='簽證' /> 
        </ul>
    )
}

export default PaymentLists ;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Single from "../src/components/Single.jsx"
import App from './App.jsx';

ReactDOM.render(
   <> 
  <BrowserRouter>
  <Routes>
        <Route path='/' element={<App/>}/>
        <Route path='single/' element={<Single/>}/>
  </Routes>
  </BrowserRouter>
  </>,
  document.getElementById('root')
);


import React from "react";
import { List } from "./List";

const MoreInfoLists = () => {
    return (
        <ul className='list-group bg-transparent m-0 p-0'>
            <List  text ='網站地圖' />
            <List  text ='追蹤我的訂單' />
            <List  text ='隱私權政策' />            
            <List  text ='正品政策' />                
        </ul>
    )
}

export default MoreInfoLists ;
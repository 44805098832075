import React from 'react'
import OurPartners from './OurPartners'
import "../Styles/StyleSingle.css"
import { LatestProducts , bestProduct , specialProducts} from './Products'
import {Link} from "react-router-dom"

const Section = () => {
    
    return (
        <section className='mt-2'>
           <div className="d-flex justify-content-between justify-content-md-start header py-2 border-bottom">
               <h5 className='m-0 p-0 ms-2 fw-bold'> 為你推薦 </h5>
               <span className='ms-md-5 m-0 p-0 align-self-center me-2 me-md-auto'>  查看所有商品 </span>
           </div>

           {/* display item as  grid in  devices  with large screens*/}
            <div className='desktop-product-container row mb-2  mt-2 w-100 p-0 mx-auto  justify-content-center'>
                    <ProductItems  className="col-4 mt-3 " />
            </div>

            {/* display items in  slide view in  devices  with small screens ( mobile devices )*/}
            <div className='mobile-product-container mt-3 mb-2 d-sm-none'>
                <ProductItems className='ms-2' />
            </div>

            {/* <div className='container-fluid mt-3 mobile-accessories'>
                <img
                      src={ require('../Assets/webaccess.jpg').default }
                      alt="konga assets" 
                      className='w-100 p-0 m-0'
                />
            </div>  */}

            <div className="amazing-product row mx-auto  mt-3">
                    <img
                            src={ require('../Assets/img/banner2.png').default }
                            alt="konga assets" 
                            className='col-6 pe-1'
                    />
                    <img
                            src={ require('../Assets/img/banner3.png').default }
                            alt="konga assets" 
                            className='col-6 ps-1'
                    />
            </div>
            
            <div className="container-fluid p-0 m-0 p-sm-2 best-selling-product mt-3 mt-md-1">
                  <h5 className="header py-2 py-md-1 ps-2">
                  最暢銷產品
                  </h5>
                  <div className="products mt-2 ">

                        {  bestProduct.map( (item,index) => {
                               return (
                                   <img 
                                        src = { item }
                                        key = { index }
                                         alt="konga assets"
                                         className='d-inline-block me-3 me-md-4'
                                    />
                               )
                        }) }

                  </div>
                  <div className="special-products pt-5 pt-md-4">
                        
                         {  specialProducts.map( (product, index) => {
                              return (
                                  <div className='d-inline-block ms-1 me-2 mt-0 pt-0 item border'
                                        key = { index }>
                                       <img 
                                              src = { product.ProductImageSrc} 
                                              alt="konga assets" 
                                              className='p-0 m-0 w-100'
                                         />
                                         <h5 className='p-0 m-0 mt-2 mt-md-1 px-1'> 
                                            {  product.name }
                                          </h5>
                                         <p className="description p-0 m-0 mt-2 word-wrap px-1">
                                             { product.description }
                                         </p>
                                         <Link to={`/single/#${index}`}>
                                         <button className=' m-0  px-1'> 
                                         閱讀更多<i className="fas fa-chevron-right ms-2 "></i>
                                          </button> 
                                         </Link>
                                  </div> 
                              )
                         } ) }

                  </div>
                  {/* a component that contains brand partners images */}
                  {/* <OurPartners /> */}
                  <div className="about-us m-0 p-0  pt-3 pt-md-2 ">

                  </div>
            </div>
            
        </section>
    )
}

const ProductItems = ({ className }) => {
     return (
              <>
                  { 
                    LatestProducts.map( 
                        (item,index) => < SingleItem
                                                key={index}
                                                item =  { {...item} }
                                                className = { className }
                                            />
                            )
                  }
              </>
            )
}

const SingleItem = ({ item , className}) => {     
    
    return(
        <div className={ className }>
             <div className="pt-3 pb-3 ps-3">
                 <img src={item.productImage}
                      alt="konga assets" 
                      className='p-0 m-0 w-50'
                 />
                 <div className='latestex'>
                  <div className='w-50'> { item.name } </div>  
                  <div className='w-50'> { item.name1 } </div>  
                  <div className='w-50'> { item.name3 } </div>  
                 </div>
                
             </div>
        </div>
    )

}

export default Section
